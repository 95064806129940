import React, {useState, useEffect} from 'react';
import Profile from './assets/profile.jpg'
import './App.css';

function App() {
  const [dadJokes, setDadJokes] = useState({setup:"loading...", punchline: "You kids need to experience dialup."});

  useEffect(() => {
    const getJoke = async () => {
      const jokeJson = await fetch('https://us-central1-dadsofunny.cloudfunctions.net/DadJokes/random/jokes', {headers: {"Accept": "applications/json"}});
      const jokeObj = await jokeJson.json();
      setDadJokes(jokeObj);
    }
    getJoke();
  }, []);

  return (
    <div className="App">
      <div className="min-h-screen bg-gradient-to-b from-black to-yellow-700 flex justify-center items-center">
        <div className="max-w-sm  bg-black p-6 rounded-md tracking-wide shadow-2xl">
        {/* <div className="max-w-sm bg-gradient-to-br from-red-700 via-gray-400 to-white p-6 rounded-md tracking-wide shadow-2xl"> */}
            <div id="header" className="flex items-center mb-4"> 
              <img alt="avatar" className="w-20 rounded-full border-2 border-red-600" src={Profile} />
              <div id="header-text" className="leading-5 ml-6 sm">
                  <h4 id="maybeName" className="text-2lx text-gray-600 font-semibold">Mr. Willy</h4>
                  <h5 id="maybeTopic" className="text-gray-700">As a father, I can never have too much practice</h5>
              </div>
            </div>
            <div id="quote" className="animate-fade-in-down">
              <q className="text-xl text-purple-600">{dadJokes.setup}</q>
              <br></br>
              <q className="italic text-purple-400">{dadJokes.punchline}</q>
            </div>
        </div>
      </div>
    </div>
  );
}

export default App;
